@import url("https://use.typekit.net/fvk4nfp.css");

@font-face {
  font-family: "Butler";
  src: url("./Assets/Fonts/Butler-Black.woff2") format("woff2"),
    url("./Assets/Fonts/Butler-Black.woff") format("woff");
  font-weight: 900;
  font-style: normal;
}

@font-face {
  font-family: "Butler";
  src: url("./Assets/Fonts/Butler-Bold.woff2") format("woff2"),
    url("./Assets/Fonts/Butler-Bold.woff") format("woff");
  font-weight: bold;
  font-style: normal;
}

@font-face {
  font-family: "Butler";
  src: url("./Assets/Fonts/Butler-ExtraBold.woff2") format("woff2"),
    url("./Assets/Fonts/Butler-ExtraBold.woff") format("woff");
  font-weight: 800;
  font-style: normal;
}

@font-face {
  font-family: "Butler";
  src: url("./Assets/Fonts/Butler.woff2") format("woff2"),
    url("./Assets/Fonts/Butler.woff") format("woff");
  font-weight: normal;
  font-style: normal;
}

$color1: #009DF1;

$color1opacity30: #009DF130;
$color1opacity42: #009DF142;
$color1opacity60: #009DF160;

$color2: #76CEFD;
$color3: #444444;

$primaryfont: "lato";

body { background-color: #004266; font-family: "lato", sans-serif; color: $color3; margin: auto; overflow-x: hidden; }

.desktop { display: block; }
.mobile { display: none; }

header {
  & { background: #FFFFFF; height: 111px; width: 100%; margin-bottom: 30px; }
  // .header-wrapper { display: flex; align-items: center; justify-content: space-between; height: 126px; max-width: 1100px; width: 100%; margin: 0 auto; }
  .header-wrapper { display: grid; grid-template-columns: 0.35fr 1fr 0.35fr; align-items: center; justify-content: space-between; height: 111px; max-width: 1100px; width: 100%; margin: 0 auto; }
  .logo { 
    // & { position: absolute; left: 50px; cursor: default; }
    & { width: 262px; display: flex; align-items: center; justify-content: center; margin: 0 auto; }
    img { max-height: 60px; max-width: 200px; padding: 0 5px; }
  }
  .assessment-title { 
    & { flex: 1 1 auto; text-align: center; color: $color3; }
    .logo-text { font-size: 32px; margin: 0 auto; font-weight: 700; line-height: 43px; }
    .logo-subtext { font-size: 21px; margin: 0 auto; font-weight: 700; line-height: 25px; letter-spacing: 8px; }
    img { height: 29px; max-width: 433px; margin-top: 5px; } 
  }
  // .bleat-logo { position: absolute; right: 50px; }
  .bleat-logo { padding: 0 10px; margin: 0 auto; }
}

main {
  .assessment-progress-sections-container { & { margin: 0 auto 60px; text-align: center; position: relative; color: #FFFFFF; max-width: 1100px; }
    .main-sections-dots { & { position: relative; max-width: 1100px; height: 3px; background-color: #FFFFFF; margin: 0 auto; display: flex; justify-content: space-between; }
      .main-section { & { position: relative; }
        .main-section-dot { position: relative; bottom: 5px; margin: 0 auto; width: 10px; height: 10px; background-color: #FFFFFF; border: 2px solid black; border-radius: 50%; }
        .main-section-dot.finished { box-sizing: border-box; width: 25px; height: 25px; bottom: 12px; background-color: #FDBF04; display: flex; justify-content: center; align-items: center; }
        .main-section-dot.active { box-sizing: border-box; height: 25px; width: 25px; bottom: 12px; background-color: #FFFFFF; background-image: radial-gradient(white 40%, #FDBF04 40%); }
        .main-section-dot.unfinished { box-sizing: border-box; height: 25px; width: 25px; background-color: #E9E9E9; bottom: 12px; border: 2px solid black; }
      }
    }
    .main-sections-names { & { position: relative; max-width: 1100px; height: 3px; margin: 0 auto; display: flex; justify-content: space-between; }
    .main-section { & { position: relative; width: 10ch; }
      .main-section-name { position: relative; margin-top: 20px; text-align: center; text-transform: uppercase; }
      .main-section-name.finished { color: #FFFFFF; font-weight: 400; }
      .main-section-name.active { color: #FDBF04; font-weight: 700; }
      .main-section-name.unfinished { color: #FFFFFF; font-weight: 300; }
    }
    }
  }
  .container { margin: 0px auto 80px; max-width: 1110px; border-radius: 10px; box-shadow: 0px 1px 20px rgba(0, 0, 0, 0.0945336); }
  .loading { display: flex; justify-content: center; align-items: center; }
  
  .start { 
    & { height: 580px; background: #ffffff; display: flex; justify-content: center; }
    .video { 
      & { border-radius: 8px 0 0 8px; position: relative; width: 50%; background: #333; height: 100%; display: flex; justify-content: center; object-fit: cover; overflow: hidden; }
      video { margin: 0px 0 0 -50px; }
      .thumbnail { position: absolute; height: 100%; overflow: hidden; }
      .caption { & { position: absolute; bottom: 0; text-align: center; width: 100%; background-color: #00000099; padding: 6px 0 12px; }
        p { margin: 2px auto; color: #FFFFFF; font-weight: 600; font-size: 18px; line-height: 22px; }
        p.name { color: #009DF1; font-weight: 700; font-size: 24px; line-height: 29px; }
      }
      .playbtn { position: absolute; top: 50%; left: 50%; transform: translate(-50%, -50%); cursor: pointer; height: 144px; width: 144px; }
    }
    .intro {
      & { width: 42%; padding: 0 4%; display: flex; align-items: left; flex-direction: column; justify-content: center; }
      h1 { margin: 0; margin-bottom: 38px; font-family: "lato", sans-serif; font-weight: 700; font-size: 38px; color:$color3; line-height: 46px; width: auto; }
      h2 { margin: 0; margin-bottom: 0; font-weight: 300; font-style: normal; color: $color3; font-size: 24px; line-height: 27px; max-width: 100%; max-width: 27ch; }
      .intro-points-list { margin: 0; margin-bottom: 0; font-weight: 300; font-style: normal; color: $color3; font-size: 24px; line-height: 35px; max-width: 100%; max-width: 27ch; font-size: 24px; }
      .completion { 
        & { display: flex; align-items: center; margin-top: 50px; color: #343333; font-weight: 700; font-size: 18px; }
        .cta { display: block; background: $color1; border-radius: 3px; color: #fff; text-decoration: none; width: 193px; text-align: center; font-family: "lato", sans-serif; font-size: 21px; font-weight: 700; text-transform: uppercase; padding: 12px 0 12px; margin-left: 0; }
        .cta:hover { background: $color2; transition: 0.3s; color: #fff; }
        .time-estimate {
          & { margin: 15px auto 15px 45px; }
          .clock-icon { width: 25px; height: 25px; vertical-align: bottom; }
        }
      }
    }
  }

  .pre-assessment { 
    & { color: $color2; max-width: 65ch; margin: 0px auto 15px; padding: 15px; font-family: $primaryfont; display: flex; flex-direction: column; justify-content: center; }
    h1 { font-size: 28px; font-weight: 600; line-height: 32px; text-align: center; }
    h2 { 
      & { font-weight: 400; text-align: center;}
      span { font-weight: 600; color: $color2;}
    }
    p { color: #696969; font-size: 18px; line-height: 25px; text-align: center; font-weight: 400;}
    .pre-assessment-subheader { font-weight: 600; text-align: center; font-size: 18px; }
    .explanation {
      & { display: flex; flex-direction: row; justify-content: center; align-items: center; font-size: 18px; }
      p { font-weight: 500; display: block; }
      .marker { box-sizing: border-box; flex: 0 0 30px; height: 30px; width: 100%; border-radius: 50%; background-color: white; margin: 10px 20px; }
      .red { border: 5px solid #FF2121; }
      .yellow { border: 5px solid #FDBF04; }
      .green { border: 5px solid #73B76F; }
    }
    .cta-link { text-decoration: none; margin: 30px auto 15px; }
    .cta { font-family: $primaryfont; color: #fff; display: block; margin: 0 auto; width: 240px; text-align: center; background-color: $color1; border-radius: 4px; font-weight: 700; font-size: 21px; letter-spacing: 1px; padding: 3px 15px; }
    .cta:hover { background-color: $color2; }
  }

  .assessment {
    & { min-height: 580px; background: #fff; width: 97%; }
    .steps {
      & { margin: 0 auto 50px; width: 82.5%; min-height: 480px; }
      .spacer { height: 20px; }
      .progress-percent-text { transition: 0.4s linear; font-family: $primaryfont; font-size: 21px; font-weight: 700; color: #007670; margin-top: 10px; margin-bottom: 0; line-height: 25px; width: 4ch; max-width: calc(100% - 3ch); }
      .progress-bar {
        & { display: flex; justify-content: space-between; width: 100%; margin-top: 16px; }
        .bar-section { 
          .progress-percentage { text-align: right; font-weight: 700; font-size: 1.1rem; margin: 1rem 0 0.5rem 1rem; }
          .progress-grid { 
            display: grid; grid-template-columns: repeat(3, 1fr); max-width: 42px; margin: 2.5rem auto;
            .grid-square { width: 10px; height: 10px; margin: 2px; } 
          }
          .bar { height: 6px; margin: 0 5px 10px; }
          .section-title { width: 100%; text-align: center; font-family: 'lato', sans-serif; font-weight: 400; color: #343333; font-size: 16px; }
        }

        .MuiLinearProgress-root.custom-bar { background-color: #E9E9E9; height: 6px; border-radius: 3px; }
        .custom-bar .MuiLinearProgress-bar { background-color: $color1; }
      }
      .current-section { font-weight: 700; font-size: 16px; line-height: 19px; text-align: center; letter-spacing: 2px; text-transform: uppercase; color: $color1; }
      .progress-indicator { margin: 50px auto 30px; width: 100%; text-align: center; font-family: $primaryfont, sans-serif; font-weight: 400; color: $color1; font-size: 18px; }

      .question { display: flex; justify-content: center; align-items: flex-start; min-height: 150px; max-width: 85%; margin: 0 auto; text-align: center; font-family: $primaryfont, sans-serif; font-weight: 400; color: $color3; font-size: 24px; }

      .radio-container {
        & { width: 86%; margin: 0 auto; }
        .slider-labels {
          & { margin: 0 auto; font-weight: 400; font-size: 21px; color: #343333; }
          .label {
            & { cursor: pointer; width: 20%; float: left; position: relative; }
            p { display: block; position: absolute; margin-left: -50%; margin-top: -20px; padding-top: 20px; width: 100%; text-align: center; z-index: 1000; }
            p.desktop { display: block; }
            p.mobile { display: none; }
          }
          .active { font-weight: 700; color: $color1; }
        }
        .MuiRadio-root { margin-left: -20px; }
      }
      .textarea-field { & { max-width: 540px; width: 85%; margin: 0 auto 0; background: #FFFFFF; border: 1px solid #979797; border-radius: 5px; padding: 6px 12px; }
        textarea { font-family: $primaryfont; font-size: 21px; }
      }
      .slider-container {
        & { width: 86%; margin: 0 auto 120px; }
        .slider { width: 100%; position: relative; }
        .slider-points {
          & { margin-top: -20px; width: 125%; font-weight: 300; font-size: 14px; color: #343333; position: relative; }
          .point {
            & { cursor: pointer; width: 12px; height: 12px; border-radius: 8px; margin-left: -8px; margin-top: -5px; background: #9b9b9b; position: absolute; z-index: 9; }
          }
        }
        .slider-labels {
          & { width: 125%; font-weight: 400; font-size: 21px; color: #343333; }
          .label {
            & { cursor: pointer; width: 20%; float: left; position: relative; color: $color3; }
            p { display: block; position: absolute; margin-left: -50%; margin-top: -20px; padding-top: 40px; width: 100%; text-align: center; z-index: 1000; letter-spacing: 1px; }
            p.desktop { display: block; }
            p.mobile { display: none; }
          }
          .active { font-weight: 700; color: $color1; }
        }

        .custom-slider.MuiSlider-root { z-index: 1000; color: $color1; width: calc(100% + 10px); margin-left: -5px; }

        .custom-slider .MuiSlider-rail { opacity: 0; }
        .custom-slider .MuiSlider-track { border: 5px solid $color1; }

        .custom-slider .MuiSlider-thumb { background-color: $color1; padding: 10px; border: 3px solid $color1; }
        .custom-slider .MuiSlider-thumb:before { background: $color1; border: 4px solid white; width: 4px; height: 4px; padding: 2px; }
        .custom-slider .MuiSlider-thumb.Mui-focusVisible { box-shadow: 0px 0px 0px 8px rgba(247, 213, 77, 0.5); }
        .custom-slider .MuiSlider-thumb:hover { box-shadow: 0px 0px 0px 8px rgba(247, 213, 77, 0.5); }
        
        .MuiLinearProgress-root.custom-bar { background-color: #E9E9E9; }
        .custom-bar .MuiLinearProgress-bar { background-color: $color1; top: -1px; }

        // .css-eglki6-MuiLinearProgress-root {
        //   background: #d8d8d8;
        // }
        // .css-5xe99f-MuiLinearProgress-bar1 {
        //   background: $color1;
        // }
        // .css-eg0mwd-MuiSlider-thumb {
        //   background: $color1;
        // }
        // .css-eg0mwd-MuiSlider-thumb:before {
        //   background: #fff;
        //   border: 4px solid $color1;
        //   width: 14px;
        //   height: 14px;
        // }
        // .css-eg0mwd-MuiSlider-thumb.Mui-focusVisible {
        //   box-shadow: 0px 0px 0px 8px rgba(247, 213, 77, 0.5);
        // }
        // .css-eg0mwd-MuiSlider-thumb:hover { box-shadow: 0px 0px 0px 8px rgba(247, 213, 77, 0.5); }
      }

      .slider-none-adjust { height: 35px; }
      .step-buttons {
        & { display: flex; justify-content: space-between; margin: 30px auto 30px; padding-bottom: 30px; }
        button { color: #343333; font-weight: 600; font-size: 14px; font-family: "lato", sans-serif; padding: 12.5px 25px; }
        .next-btn { background-color: $color1; color: #FFFFFF; }
      }
    }
  }

  .pre-additional-info { & { width: 97%; max-width: 1100px; padding: 15px 30px 30px; margin: 30px auto; box-sizing: border-box; background-color: white; text-align: center; }
    h1 { font-size: 24px; font-weight: 400; margin: 15px auto; padding: 30px 30px 10px; }
    p { font-size: 24px; font-weight: 300; max-width: 65ch; margin: 30px auto; }
    .radio { font-size: 24px; font-weight: 300; max-width: 65ch; margin: 30px auto; }
    .MuiTypography-root {font-size: 20px; font-weight: 300; max-width: 30ch; font-family: "lato", sans-serif;}
    .Mui-checked { color: #F4D62B; }
    .Mui-checked + .MuiTypography-root {font-weight: 600; font-size: 19px;}
    .MuiFormControlLabel-root { width: 23ch; margin: 0 auto;}
  }
  .pre-additional-info, .pre-additional-questions-details, .assessment-additional {
    .step-buttons { & { display: flex; justify-content: space-between; align-items: center; max-width: 85%; margin: 15px auto; padding: 30px 15px; }
      .cta-prev { padding: 11px 25px; color: $color3; font-size: 16px; font-weight: 400; font-family: $primaryfont; }
      .cta { margin: 0; font-size: 16px; font-weight: 700; background-color: $color1; color: #FFFFFF; padding: 11px 25px; font-family: $primaryfont; }
      .cta:hover { background-color: $color2; color: white; }
      // .nextbtn { background: $color1; color: #fff; padding: 14px 26px; border-radius: 35px; font-weight: 700; }
      // .nextbtn:hover { background-color: $color2; }
      // .nextbtn.Mui-disabled { background: #C1C1C1; }
      // .all-set-btn { padding: 14px 2.5rem; }
    }
  }


  .assessment-additional {
    & { min-height: 580px; background: #fff; border-radius: 10px; color: $color3; width: 95%; }
    .steps {
      & { margin: 0 auto 50px; width: 80%; min-height: 480px; }
      .spacer { height: 50px; }
      h1 { font-size: 18px; text-align: center; }

      .progress-indicator { margin: 2.5rem auto; width: 100%; text-align: center; font-family: "lato", sans-serif; font-weight: 400; color: $color1; font-size: 18px; }

      .question { min-height: 150px; text-align: center; font-family: "lato", sans-serif; font-weight: 400; font-size: 24px; }
      .textarea { min-height: 80px; }
      .textarea-field { max-width: 540px; width: 80%; margin: 0 auto; margin-bottom: 0; }
      .MuiInput-root { font-family: 'lato', sans-serif !important; font-size: 21px; border: 1px solid #979797; padding: 14px 18px; border-radius: 5px; }
      .form-details-text { background-color: #fff; padding: 0 9px 22px; height: 90px; }

      .slider-container {
        & { width: 86%; margin: 0 auto 120px; }
        .slider { width: 100%; position: relative; }
        .slider-points {
          & { margin-top: -20px; width: 125%; font-weight: 300; font-size: 14px; color: #182036; position: relative; }
          .point {
            & { cursor: pointer; width: 12px; height: 12px; border-radius: 8px; margin-left: -8px; margin-top: -5px; background: #9b9b9b; position: absolute; z-index: 9; }
          }
        }
        .slider-labels {
          & { width: 125%; font-weight: 400; font-size: 21px; color: $color3; }
          .label {
            & { cursor: pointer; width: 20%; float: left; position: relative; }
            p { display: block; position: absolute; margin-left: -50%; margin-top: -20px; padding-top: 40px; width: 100%; text-align: center; z-index: 1000; }
          }
          .active { font-weight: 700; color: $color1; }
        }

        .custom-slider.MuiSlider-root { z-index: 1000; color: $color1; }

        .custom-slider .MuiSlider-rail { opacity: 0; }
        .custom-slider .MuiSlider-track { border: 5px solid $color1; }

        .custom-slider .MuiSlider-thumb { background: $color1; }
        .custom-slider .MuiSlider-thumb:before { background: #fff; border: 4px solid $color1; width: 14px; height: 14px; }
        .custom-slider .MuiSlider-thumb.Mui-focusVisible { box-shadow: 0px 0px 0px 8px rgba(247, 213, 77, 0.5); }
        .custom-slider .MuiSlider-thumb:hover { box-shadow: 0px 0px 0px 8px rgba(247, 213, 77, 0.5); }
        
        .MuiLinearProgress-root.custom-bar { background-color: #E9E9E9; }
        .custom-bar .MuiLinearProgress-bar { background-color: $color1; top: -2px; }

        // .css-eglki6-MuiLinearProgress-root {
        //   background: #d8d8d8;
        // }
        // .css-5xe99f-MuiLinearProgress-bar1 {
        //   background: $color1;
        // }
        // .css-eg0mwd-MuiSlider-thumb {
        //   background: $color1;
        // }
        // .css-eg0mwd-MuiSlider-thumb:before {
        //   background: #fff;
        //   border: 4px solid $color1;
        //   width: 14px;
        //   height: 14px;
        // }
        // .css-eg0mwd-MuiSlider-thumb.Mui-focusVisible {
        //   box-shadow: 0px 0px 0px 8px rgba(247, 213, 77, 0.5);
        // }
        // .css-eg0mwd-MuiSlider-thumb:hover { box-shadow: 0px 0px 0px 8px rgba(247, 213, 77, 0.5); }
      }

      .slider-none-adjust { height: 35px; }
      .step-buttons {
        & { display: flex; justify-content: space-between; margin: 30px auto 30px; padding-bottom: 30px; }
        button { color: #182036; font-weight: 600; font-size: 16px; font-family: "lato", sans-serif; }
        .nextbtn { background: $color1; color: #fff; padding: 14px 26px; border-radius: 35px; font-weight: 700; }
        .nextbtn:hover { background-color: $color2; }
        .nextbtn.Mui-disabled { background: #C1C1C1; }
        .all-set-btn { padding: 14px 2.5rem; }
      }
    }
  }

  .details, .pre-additional-questions-details {
    & { min-height: 630px; max-width: 1110px; width: 95%; background: #fff; }
    h1 { width: auto; text-align: center; margin: 0 auto; font-family: "lato", sans-serif; font-size: 36px; color: $color3; padding: 32px 15px 0; }
    p { text-align: center; font-size: 21px; font-weight: 400; max-width: 40ch; line-height: 1.4em; color: $color3; padding: 0 15px; margin: 15px auto; }

    .form {
      & { display: flex; justify-content: center; flex-wrap: wrap; margin: 43px auto 0px auto; width: 65%; }
      .form-detail { 
        * { box-sizing: border-box; }
        & { position: relative; flex: 0 1 46%; max-width: 46%; display: block; margin-bottom: 20px; font-weight: 700; padding: 0 2%; /* overflow: hidden; */ }
        p { padding: 0; margin-bottom: 5px; text-align: left; font-size: 16px; font-weight: 700; }
        .MuiInput-root { font-family: 'lato', sans-serif !important; font-size: 18px; font-weight: 400; }
        .MuiInput-input { height: 28px; }
        .MuiSelect-select .first-menu { color: #fff; }
        
        // .form-details-text { background-color: #F4F4F4; padding: 15.6px 15px; padding: 10px 15px; max-width: 100%; }
        .form-details-text { background-color: #F4F4F4; max-width: 100%; border-radius: 3px; }
        .form-details-text .MuiInput-root { padding: 10px 15px; border-radius: 3px; border: 1px solid transparent; }
        .form-details-text .MuiInput-root.Mui-focused { border: 1px solid $color1; }
        
        
        .form-details-text.react-select div:nth-child(3) { border: none; }
        .form-details-text.react-select .react-select__placeholder { white-space: nowrap; overflow-x: hidden; padding-left: 4px; }
        .form-details-text.react-select { & { padding: 0; font-size: 18px; font-family: "lato", sans-serif; font-weight: 400; }
          div:first-child { padding-top: 6.25px; padding-bottom: 6.25px; }
        }

        .form-details-text input:focus::placeholder { color: transparent; font-weight: 300; }
        .form-details-text {
          input[type=number]::-webkit-inner-spin-button, 
          input[type=number]::-webkit-outer-spin-button { 
            -webkit-appearance: none;
            -moz-appearance: none;
            appearance: none;
          }
        }
        // .error-text { position: absolute; bottom: -20px; color: red; font-weight: 400; font-size: 13px; }
        .error-text { color: red; font-weight: 400; font-size: 13px; margin: 2px auto -15px; }
        .required-field { color: $color1; }
        input:-webkit-autofill,
        input:-webkit-autofill:hover, 
        input:-webkit-autofill:focus, 
        input:-webkit-autofill:active{
            -webkit-box-shadow: 0 0 0 30px #F4F4F4 inset !important;
        }
      }
      .form-detail:last-child.school-name {
        flex: 0 1 100%; max-width: 100%; width: 100%;
      }
    }

    // .details-buttons { & { display: grid; grid-template-columns: 1fr 1fr 1fr; max-width: 80%; margin: 0 auto; padding-top: 16px; padding-bottom: 3rem; } 
    .details-buttons { & { display: flex; justify-content: space-between; max-width: 80%; margin: 0 auto; padding-top: 16px; padding-bottom: 3rem; } 
      .back-btn { & { align-self: center; } button { color: #444444; padding: 10px 15px; }}
      .cta-btn {
        & { margin: 0 auto; text-decoration: none; align-self: center; }
        a { text-decoration: none; }
        button { width: 280px; background: $color1; font-family: "lato", serif; font-size: 21px; font-weight: 700; color: #fff; border-radius: 3px; padding: 8px 0; text-align: center; text-transform: uppercase; }
      button:hover { background: $color2; transition: 0.3s; color: #fff; }
      .button-progress { margin: 4px 0 -2px; }
    }
    }
  }

  .results {
    & { min-height: 660px; background: #fff; color: $color3; width: 96%; }
    .spacer { height: 20px; }

    .selection-form { 
      &  { width: 50%; display: flex; align-items: center; margin: 20px auto; flex-wrap: wrap; justify-content: center; text-align: center; font-size: 36px; font-weight: 400; }
      .form-detail { flex: 0 1 100%; margin-right: 4%;}
      .form-text { flex: 0 1 100%; }
      .form-details-text { 
        & { background-color: #fff; border: 0px solid #efefef; border-bottom: 4px solid $color1; padding: 3px 7px; max-width: 90%; }
        .MuiInput-root { font-size: 36px; font-family: "lato", sans-serif; }
      }
    }

    h1 { width: auto; text-align: center; margin: 0 auto; font-family: 'lato', sans-serif; font-size: 36px; color: $color3; padding: 32px 32px 0; max-width: 45ch;
      .user-name { text-transform: capitalize; }
    }
    
    .progress-bar { margin: 115px auto 100px; position: relative; max-width: 90%; }
    .single-group_assessment #group-sections .si_ass_result { padding: 7% 0px 3%; }
    span.msi_name { color: $color1; font-size: 18px; font-weight: 900; font-family: "Avenir", Sans-serif; }
    .pro_top_details span { display: block; line-height: 30px; min-width: 180px; }
    .pro_top_details{ position: absolute; bottom: 45px; left: 0; max-width: 130px; width:100%; -webkit-transition: all .5s ease-in-out; -moz-transition: all .5s ease-in-out; -o-transition: all .5s ease-in-out; transition: all .5s ease-in-out; }
    .in-ass-result .si_ass_progress span, .single-assesment .si_ass_progress span { position: absolute; font-size: 30px; bottom: 42px; left: 0; font-weight: bold; color: #4A4A4A; font-family: "Aleo", Sans-serif; }
    .si_ass_progress:before {  }
    .single-group_assessment #group-sections .si_ass_progress:before { height: 45px; }

    .si_ass_progress { position: relative; max-width: 700px; height: 26px; margin: 0 auto; border-radius: 12px; width: 100%; 
      background-image: linear-gradient(to right, #cc2c24, #ffca26 62%, #429653 100%); 
      // background-image: linear-gradient(to right, #cc2c24 69.5%, #E98526 70% 79.5%, #FFCA27 80% 94.5%, #4E9A51 95% 100%);
    }
    .si_ass_progress .progress-line { z-index: 2; width: 2px; height: 35px; content: ' '; background: $color3; left: 0; bottom: 0; position: absolute; -webkit-transition: all .5s ease-in-out; -moz-transition: all .5s ease-in-out; -o-transition: all .5s ease-in-out; transition: all .5s ease-in-out; }
    .si_ass_progress .progress-line .progress-text { position: absolute; top: -65px; left: -100px; width: 200px; font-size: 18px; color: $color3; height: 30px; text-align: center; }
    .si_ass_progress .msi_score { display: block; position: absolute; font-size: 30px; font-weight: bold; color: #4A4A4A; bottom: 35px; left: 0; margin-left: -20px; }
    
    
    // .si_ass_progress .msi_score::after { display: block; margin: 0 33% 1rem 33%; content: ""; height: 50px; background-color: black; width: 2px; color: black; }
    
    
    
    .pervalue h4 { position: relative; top: 105px; }
    .pervalue { max-width: 700px; margin: -68px auto; display: flex; clear: both; color: #4A4A4A; font-size: 16px; }
    // .pervalue h4.nimp1:after { content: '0%'; position: absolute; right: -19px; bottom: 35px; }
    // .pervalue h4.nimp:after { content: '45%'; position: absolute; right: -19px; bottom: 35px; }
    // .pervalue h4.mexpe:after { content: '70%'; position: absolute; right: -19px; bottom: 35px; }
    // .pervalue h4.eexpe:after { content: '100%'; position: absolute; right: -19px; bottom: 35px; }
    .pervalue h4.nimp1:after { content: "0%"; position: absolute; right: -19px; bottom: 35px; }
    .pervalue h4.nimp:after { content: "50%"; position: absolute; right: -19px; bottom: 35px; }
    .pervalue h4.mexpe:after { content: "80%"; position: absolute; right: -19px; bottom: 35px; }
    .pervalue h4.eexpe:after { content: "100%"; position: absolute; right: -19px; bottom: 35px; }
    .pervalue h4.eexpe1:after { content: "80%"; position: absolute; right: -19px; bottom: 35px; }
    .pervalue h4.eexpe2:after { content: "100%"; position: absolute; right: -19px; bottom: 35px; }
    // .pervalue-text h4.nimp:after { content: 'Not Aligned'; position: absolute; right: -19px; bottom: -75px; }
    // .pervalue-text h4.mexpe:after { content: 'Needs Better Alignment'; position: absolute; right: -19px; bottom: -75px; }
    // .pervalue-text h4.eexpe:after { content: 'Aligned'; position: absolute; right: -19px; bottom: -75px; }
    .pervalue-text { & { position: relative; display: block; margin: 0 auto; width: 100%; max-width: 700px; bottom: -55px; }
      p { position: absolute; display: inline-block; width: 10ch; text-align: center; margin: 0 auto; top: 15px; font-size: 18px; vertical-align: top; color: #575757; }
    }

    .si_pro_header { max-width: 700px; margin: 10px auto 0; display: flex; clear: both; }
    .si_pro_header h4 { position: relative; font-size: 16px;  font-weight: 400; text-align: center; color:  $color3; text-transform: capitalize; margin-top: 10px; }
    .si_pro_header h4.nimp:before { width: 2px; height: 28px; content: ' '; background: #fff; position: absolute; right: 0; bottom: -75px; }
    .si_pro_header h4.mexpe:before { width: 2px; height: 28px; content: ' '; background: #fff; bottom: -75px; position: absolute; right: -2px; }
    .si_pro_header h4.eexpe:before { width: 2px; height: 28px; content: ' '; background: #fff; bottom: -75px; position: absolute; right: -2px; }
    .si_pro_header h4.eexpe1:before { width: 2px; height: 28px; content: ' '; background: #fff; bottom: -75px; position: absolute; right: 0; }
    .si_pro_header h4 span { color: $color1; line-height: 35px; font-size: 16px; }
    
    .result-text { text-align: center; font-size: 24px; margin: 20px auto; max-width: 65ch; background-color: #FFFFFF; padding: 5px 30px; border-radius: 10px; color: #121212; }
    .result-text p { padding-top: 0; margin-block-start: 0; margin: 0 auto; }
    .result-sections { 
      & { margin: 50px 5%; display: flex; justify-content: center; flex-wrap: wrap; padding-bottom: 50px; }
    }
    .score-section-container {
      & { display: flex; flex-direction: row; justify-content: center; align-items: center; max-width: 800px; padding: 0rem 2rem 3rem 2rem; margin: 1rem auto; }
      .score-section { 
        & { flex: 0 1 25%; text-align: center; color: $color3; font-size: 18px; font-weight: 700; margin: 10px auto; }
        .score-txt { font-size: 21px; font-weight: 700; margin-bottom: 6px; }
        .score-name { font-size: 18px; font-weight: 700; margin: 0.25rem auto; }
        .score-slider { width: 90%; margin: 0 auto; margin-bottom: 16px; }
        .MuiLinearProgress-root.custom-bar { background-color: #E9E9E9; height: 10px; border-radius: 5px; width: 100%; }
        .custom-bar .MuiLinearProgress-bar { background-color: $color1; }
      }
    }

    
    .selection-form .form-details-text .MuiInput-root .MuiSelect-select { padding-right: 0; }
    //.css-eglki6-MuiLinearProgress-root { background: #E9E9E9; height: 5px; }
    //.css-5xe99f-MuiLinearProgress-bar1 { background: $color1; height: 5px; }

    .members-answered {
      & { max-width: calc(880px - 10%); background: #FBFBFB; margin: 20px auto; padding: 0px 0% 0px; font-family: "lato", sans-serif; font-size: 18px; color: #1f2a44; text-align: left; border-radius: 8px; }
      .section {
        & { background-color: #004266; color: #fff; padding: 10px 17px; margin: 0; font-weight: 700; border-top-left-radius: 3px;  border-top-right-radius: 3px; display: flex; align-items: center; justify-content: space-between; cursor: pointer; }
        .section-toggle { display: block; cursor: pointer; height: 24px; }
      }
      .answered-detail { 
        & { font-weight: 400; font-size: 18px; color: $color3; padding: 10px 30px; width: calc(100% - 60px); display: flex; align-items: center; }        
        .answer { flex: 1 1 285px; margin-left: 10px; text-align: right; font-weight: 700; }
        p { margin: 0; }
        .true { color: #73B76F; }
        .neutral { color: #FFB545; }
        .false { color: #FF6645; }
      }
      .answered-detail:nth-child(even) { background-color: #f4f4f4; }      
    }
  }

  .invite {
    & { min-height: 380px; width: 97%; max-width: 1110px; padding-bottom: 30px; background: #fff; }
    h1 { width: 100%; text-align: center; margin: 0 auto 25px; font-family: "lato", sans-serif; font-size: 36px; color: $color3; padding: 67px 15px 0; }
    p { text-align: center; margin: 5px auto 10px; padding: 0 10px; font-size: 21px; line-height: 1.4em; color: $color3; max-width: 60ch; }
    p:nth-child(3) { margin-bottom: 35px; }
    .form {
      & { position: relative; display: flex; justify-content: space-between; flex-wrap: wrap; margin: 30px auto; width: 45%; }
      .invite-number { position: absolute; left: -50px; top: 45px; font-weight: bold; color: #C2C2C2; }
      .form-detail {
        & { flex: 0 1 46%; display: block; margin-bottom: 12px; font-weight: 700; }
        p { padding: 0; margin-bottom: 5px; text-align: left; font-size: 16px; font-weight: 700; color: #4A4A4A; }
        .MuiInput-root { font-family: 'lato', sans-serif !important; font-size: 18px; font-weight: 400; }
        .invite-field { font-family: 'lato', sans-serif !important; background-color: #F4F4F4; }
        .invite-field .MuiInput-root { border: 1px solid transparent; padding: 5px 10px; border-radius: 3px; }
        .invite-field .MuiInput-root.Mui-focused { border: 1px solid $color1; }
        .invite-field::placeholder { font-family: 'lato', sans-serif !important; font-size: 18px !important; color: #9B9B9B; font-weight: 300; }
        .invite-field input:focus::placeholder { color: transparent; }
        .error-text { position: absolute; bottom: -20px; color: red; font-weight: 400; font-size: 13px; }
      }      
      .delete-btn {
        & { position: absolute; right: -58px; top: 45px; }
        .delete-icon { color: #D5D5D5; font-size: 24px; cursor: pointer; }
      }
    }

    .invite-another-person {
      & { margin: 0 auto; width: 55%; position: relative; height: 5px; }
      .invite-another { & { width: 74px; background-color: #E5E5E5; position: absolute; top: -88px; cursor: pointer; right: -115px; border-radius: 3px; text-align: center; padding: 4px 6px; color: $color3; }
        .add-btn { font-size: 20px; padding: 0 6px 0 0; color: $color3; font-weight: 700; font-family: $primaryfont; }
        .invite-another-icon { vertical-align: sub; }
      }
    }

    .cta-btn {
      & { display: block; margin: 30px auto 50px; text-decoration: none; width: 270px; background: $color1; color: #fff; font-family: "lato", serif; border-radius: 3px; font-size: 21px; padding: 8px 0; text-align: center; text-transform: uppercase; font-weight: bold; }
      &:hover { background: $color2; transition: 0.3s; color: #fff; }
      .button-progress { margin: 4px 0 -2px; }
    }
  }

  .thank-you {
    & { min-height: 380px; width: 95%; max-width: 815px; padding-bottom: 30px; background: #fff; }
    .tick { & { text-align: center; padding: 80px 0 0; }
      img { width: 60px; height: 60px; }
    }
    h1 { width: auto; text-align: center; margin: 0 auto; font-family: "lato", serif; font-size: 36px; color: #1f2a44; padding: 32px 15px 15px; }
    p { width: 65%; margin: 0 auto; text-align: center; font-size: 21px; color: #202020; }
    .back-btn { 
      & { text-align: center; margin: 3rem auto 1rem auto; }
      a { border-bottom: 2px solid $color1; text-transform: uppercase; font-weight: bold; cursor: pointer; text-decoration: none; color: #202020; }
    }
  }

  .invite-container {
    & { margin: 50px auto 15px; padding: 0 4% 30px; font-family: "lato", sans-serif; font-size: 21px; line-height: 32px; color: #FFFFFF; text-align: center; max-width: 1100px; }
    h3 { font-weight: 700; font-size: 36px; line-height: 1.2; }
    p { max-width: 60ch; margin: 1rem auto;}
    .height30 { height: 30px; }
    .invite-reasons {
      & { display: flex; padding: 73px 0 30px; max-width: 1400px; margin: 0 auto; }
      .reason { 
        & { flex: 0 1 33%; font-size: 21px; padding: 0 10px; max-width: 26ch; margin: 0 auto; }
        span { color: $color1; font-weight: 700; }
        img { height: 61px; }
      }
    }
    .cta-btn {
      & { display: inline-block; background-color: $color1; margin: 40px auto 0; color: #fff; padding: 0; border-radius: 3px; width: 100%; max-width: 280px; }
      &:hover { background-color: $color2; transition: 0.3s; color: #fff; }
      a { display: block; text-decoration: none; color: #fff; }
      button { width: 100%; max-width: 100%; display: inline-block; padding: 5px 25px; color: #fff; font-family: "lato", sans-serif; font-size: 21px; font-weight: 700; height: 50px; border-radius: 3px; }
      button:hover { background-color: $color2; transition: 0.3s; color: #fff; }
    }
  }

  .team-container {
    & { max-width: 90%; width: auto; border-radius: 10px; margin: 50px auto; font-family: "lato", sans-serif; font-size: 24px; line-height: 36px; color: #ffffff; text-align: center; font-weight: 400; }
    h3 { line-height: 42px; font-size: 36px; color: #ffffff; }
    .cta-btn {
      & { width: 220px; background: $color1; margin: 40px auto 0; color: #fff; padding: 5px 0; border-radius: 25px; }
      &:hover { background: $color2; transition: 0.3s; color: #fff; }
      a { display: block; text-decoration: none; color: #fff; }
      button { color: #fff; font-family: "lato", sans-serif; font-size: 18px; font-weight: bold; }
    }
  }

  .individual-group {
    & { max-width: 400px; width: 90%; margin: 70px auto 45px; display: flex; }
    button { cursor: pointer; flex: 0 1 50%; border: 0px; background: #fff; color: #1F2A44; font-family: "lato", sans-serif; font-size: 18px; font-weight: 600; padding: 16px 0; text-align: center; }
    .left { border-top-left-radius: 100px; border-bottom-left-radius: 100px; font-weight: 700; color: #202945; }
    .right { border-top-right-radius: 100px; border-bottom-right-radius: 100px; font-weight: 700; color: #202945; }
    .active { background-color: $color1; color: #ffffff; font-weight: 700; }
  }

  .toggle-individual-group { display: none; }

  .dashboard {
    & { min-height: 630px; background: #fff; width: 96%; }
    h1 { width: auto; text-align: center; margin: 0 auto; font-family: 'lato', serif; font-size: 36px; color: $color3; padding: 45px 30px 0; max-width: 45ch; }
    
    .progress-bar { & { margin: 140px auto 100px; position: relative; max-width: 90%; }
      .pervalue h4 { position: relative; top: 52px; }
      .pervalue h4:after { position: absolute; right: -19px; bottom: -18px; }
      // .pervalue h4.nimp1:after { content: "0%"; position: absolute; right: -19px; bottom: 35px; }
      // .pervalue h4.nimp:after { content: "20%"; position: absolute; right: -19px; bottom: 35px; }
      // .pervalue h4.mexpe:after { content: "40%"; position: absolute; right: -19px; bottom: 35px; }
      // .pervalue h4.eexpe:after { content: "60%"; position: absolute; right: -19px; bottom: 35px; }
      // .pervalue h4.eexpe1:after { content: "80%"; position: absolute; right: -19px; bottom: 35px; }
      // .pervalue h4.eexpe2:after { content: "100%"; position: absolute; right: -19px; bottom: 35px; }
      .pervalue h4.nimp1:after { content: "0%"; }
      .pervalue h4.nimp:after { content: "50%"; }
      .pervalue h4.mexpe:after { content: "80%"; }
      .pervalue h4.eexpe:after { content: "100%"; }
      .pervalue-text { & { position: relative; display: block; margin: 0 auto; width: 100%; max-width: 700px; bottom: -70px; }
        p { position: absolute; display: inline-block; text-align: center; margin: 0 auto; font-size: 18px; width: 10ch; vertical-align: top; color: #575757; }
      } 
    }
    .single-group_assessment #group-sections .si_ass_result { padding: 7% 0px 3%; }
    span.msi_name { color: #d3202b; font-size: 18px; font-weight: 900; font-family: "Avenir", Sans-serif; }
    .pro_top_details span { display: block; line-height: 30px; min-width: 180px; }
    .pro_top_details{ position: absolute; bottom: 45px; left: 0; max-width: 130px; width:100%; -webkit-transition: all .5s ease-in-out; -moz-transition: all .5s ease-in-out; -o-transition: all .5s ease-in-out; transition: all .5s ease-in-out; }
    .in-ass-result .si_ass_progress span, .single-assesment .si_ass_progress span { position: absolute; font-size: 30px; bottom: 42px; left: 0; font-weight: bold; color: #4A4A4A; font-family: "Aleo", Sans-serif; }
    .si_ass_progress:before {  }
    .single-group_assessment #group-sections .si_ass_progress:before { height: 45px; }

    .si_ass_progress { position: relative; max-width: 700px; height: 26px; margin: 0 auto; border-radius: 12px; width: 100%; 
      background-image: linear-gradient(to right, #cc2c24, #ffca26 62%, #429653 100%);
      // background-image: linear-gradient(to right, #cc2c24 69.5%, #E98526 70% 79.5%, #FFCA27 80% 94.5%, #4E9A51 95% 100%);
    }
    .si_ass_progress .progress-line { width: 2px; height: 35px; content: ' '; background: $color3; left: 0; bottom: 0; position: absolute; -webkit-transition: all .5s ease-in-out; -moz-transition: all .5s ease-in-out; -o-transition: all .5s ease-in-out; transition: all .5s ease-in-out; }
    .si_ass_progress .progress-line .progress-text { position: absolute; top: -65px; left: -100px; width: 200px; font-size: 18px; color: $color3; height: 30px; text-align: center; }
    .si_ass_progress .msi_score { display: block; position: absolute; font-size: 30px; font-weight: bold; color:  $color3; bottom: 35px; left: 0; margin-left: -20px; }
    
    
    // .si_ass_progress .msi_score::after { display: block; margin: 0 33% 1rem 33%; content: ""; height: 50px; background-color: black; width: 2px; color: black; }
    
    
    
    .pervalue h4 { position: relative; top: 50px; }
    .pervalue { max-width: 700px; margin: -68px auto; display: flex; clear: both; color: $color3; font-size: 16px; }
    .pervalue h4.nimp:after { content: '70%'; position: absolute; right: -19px; }
    .pervalue h4.mexpe:after { content: '80%'; position: absolute; right: -19px; }
    .pervalue h4.eexpe:after { content: '95%'; position: absolute; right: -19px; }

    .si_pro_header { max-width: 700px; margin: 10px auto 0; display: flex; clear: both; }
    .si_pro_header h4 { position: relative; font-size: 16px;  font-weight: 400; text-align: center; color:  $color3; text-transform: capitalize; margin-top: 10px; }
    .si_pro_header h4.nimp:before { width: 2px; height: 28px; content: ' '; background: #fff; position: absolute; right: 0; bottom: -75px; }
    .si_pro_header h4.mexpe:before { width: 2px; height: 28px; content: ' '; background: #fff; bottom: -75px; position: absolute; right: 0; }
    .si_pro_header h4.eexpe:before { width: 2px; height: 28px; content: ' '; background: #fff; bottom: -75px; position: absolute; right: 0; }
    .si_pro_header h4.eexpe1:before { width: 2px; height: 28px; content: ' '; background: #fff; bottom: -75px; position: absolute; right: 0; }
    .si_pro_header h4 span { color: #D3202B; line-height: 35px; font-size: 16px; }
    
    .result-text { & { text-align: left; font-size: 24px; margin: 20px auto; max-width: 65ch; border-radius: 10px; }
      p { max-width: 60ch; text-align: center; margin: 2px auto; }
    }
    .result-sections { 
      & { width: 95%; max-width: 800px; margin: 50px auto; margin-bottom: 0; display: flex; justify-content: center; flex-wrap: wrap; }
      .score-section { 
        & { flex: 0 1 25%; text-align: center; font-weight: 700; font-size: 18px; margin: 10px auto; }
        .score-txt { font-size: 18px; font-weight: 700; margin-bottom: 6px; }
        .score-slider { width: 90%; margin: 0 auto; margin-bottom: 16px; }
      }
    }

    .MuiLinearProgress-root.custom-bar { background-color: #E9E9E9; height: 10px; border-radius: 5px; }
    .custom-bar .MuiLinearProgress-bar { background-color: $color1; }

    table { 
      & { font-family: 'lato', sans-serif; border-collapse: collapse; width: 86%; max-width: max-content; margin: 70px auto 40px; font-size: 18px; font-weight: 400; color: $color3; }
      tr td:first-child { font-weight: 700; }
      tr:nth-child(even) { background-color: #F4F4F4; }
      td, th { border: 1px #dddddd;text-align: left;padding: 8px; }
      thead th { font-size: 14px; }
      th.center-cell:last-child, td.center-cell:last-child { font-weight: 700; }
      .center-cell { text-align: center; text-transform: capitalize; }
      .medium { font-weight: 500; }
      .hash { width: 13px; }
      .avg { text-align: center; }
      .header { border-style: none;border-bottom: 3px solid $color1; }
      .footer { 
        & { border-style: none;border-top: 3px solid $color1; color: $color1; }
        .church-average { font-size: 14px; color: $color3; }
      }
    }

    hr { width: 96%; color: #979797; }
 
    h2 { font-family: "lato", serif; font-size: 24px; font-weight: 700; color: $color3; margin-top: 50px; text-align: center; }
    .most-answered {
      & { max-width: calc(880px - 10%); background: #FBFBFB; margin: 30px auto; padding: 0px 0% 0px; font-family: "lato", sans-serif; font-size: 18px; color: #1f2a44; text-align: left; border-radius: 10px; overflow: hidden; }
      .green { background-color: #73B76F; color: #fff; padding: 10px 17px; margin: 0; font-weight: 700; border-top-left-radius: 10px;  border-top-right-radius: 10px; }
      .red { background-color: #FF6645; color: #fff; padding: 10px 17px; margin: 0; font-weight: 700; border-top-left-radius: 10px;  border-top-right-radius: 10px; }
      .most-answered-detail { font-weight: 400; font-size: 18px; color: $color3; padding: 10px 30px; width: calc(100% - 60px);}
      .most-answered-detail:nth-child(even) { background-color: #f4f4f4; }
    }

    .alignment { 
      & { width: 96%; max-width: calc(860px - 10%); margin: 0 auto 30px; background-color: $color1; margin: 50px auto 0; border-radius: 10px; }
      .container-tabs { display: flex; flex-direction: column; position: relative; width: 100%; min-height: 400px; }
      .tab-buttons { 
        & { display: flex; width: 100%; margin: 0 auto; justify-content: center; background-color: #246080; border-radius: 10px 10px 0px 0px; padding: 10px 8px 0; box-sizing: border-box; }
        button { font-family: "lato", sans-serif; color: #fff; font-size: 18px; border-radius: 3px 3px 0 0; font-weight: 600; text-transform: uppercase; text-align: center; padding: 10px 15px; background: none; border: 0px; min-width: 110px; cursor: pointer; }
        .active { color: #152036; background-color: #A9D5ED; }
      }
      .tabs-content { flex-grow: 1; background-color: #A9D5ED; padding: 10px; border-radius: 0 0 10px 10px; }

      .content { 
        & { width: 100%; display: none; }
        .align-details {
          & { max-width: calc(880px - 10%); background: #FBFBFB; margin: 20px auto; padding: 0px 0% 0px; font-family: "lato", sans-serif; font-size: 18px; text-align: left; border-radius: 10px; overflow: hidden; }
          .green { background-color: #73B76F; color: #fff; padding: 10px 17px; margin: 0; font-weight: 700; border-radius: 10px 10px 0 0; }
          .red { background-color: #FF6645; color: #fff; padding: 10px 17px; margin: 0; font-weight: 700; border-top-left-radius: 3px;  border-top-right-radius: 3px; }
          .answered-detail { 
            & { display: flex; flex-wrap: wrap; align-items: center; font-weight: 400; font-size: 18px; color: $color3; padding: 10px 15px; width: calc(100% - 30px); cursor: pointer; }
            .answer { flex: 1 1 200px; margin-left: 10px; text-align: right; font-weight: 700; }
            .true { color: #73B76F; }
            .neutral { color: #FFB545; }
            .false { color: #FF6645; }
            p { flex: 0 1 94%; }
            .more-toggle { display: block; flex: 0 1 6%; cursor: pointer; }
            .more-details { 
              & { display: none; width: 91%; margin-left: 5%; padding: 5px 2%; border-left: 3px solid #E66767; font-size: 14px; }
              .more-row { 
                & { display: flex; align-items: center; }
                p { max-width: 30%; padding: 5px; margin: 0; }
                .more-answer { text-transform: uppercase; font-weight: bold; }
              }
            }
          }
          .no-wrap { flex-wrap: nowrap; }
          .answered-detail:nth-child(even) { background-color: #f4f4f4; }
        }
        
      }
      .active-content { display: block; }
    }

    .mobile_scores {
      & { display: none; width: 90%; margin: 0 auto; }
      .individual {
        h2 { font-size: 18px; font-weight: 700; }
        & { font-size: 21px; font-weight: 700;border-bottom: 2px solid $color1;  }
        .row { 
          & { height: 40px; display: flex; align-items: center; border-top: 2px solid $color1; cursor: pointer; }
          &:last-of-type { border-bottom: 2px solid $color1; }
          .number { flex: 0 1 30px; font-size: 18px; }
          .name { flex: 1 1 auto; }
          .percent { font-size: 18px; margin-right: 10px; color: $color1; }
          .toggle { padding-top: 6px; }
        }
        .scores { 
          & { font-size: 18px; border-top: 2px solid $color1; }
          .section_rows { 
            & { display: flex; padding: 12px 16px; }
            &:nth-of-type(even) { background-color: #F4F4F4; }
            .section { flex: 1 1 auto; }
            .section_score { flex: 0 1 auto; font-weight: 400; }
          }
        }
      }
    }
  }

  .results, .dashboard {
    .hurdles-enablers {
      & { width: 95%; max-width: 775px; margin: 30px auto 15px; }
      h2 { font-family: "lato", sans-serif; font-weight: 700; font-size: 18px; text-align: left; }
      .hurdles { background: #fbece9;  padding: 10px 4% 1px 2%; font-family: "lato", sans-serif; font-size: 16px; line-height: 1.5em; color: $color3; text-align: left; border-radius: 13px; }
      .enablers { background: #f1f8f1;  padding: 10px 4% 1px 2%; font-family: "lato", sans-serif; font-size: 16px; line-height: 1.5em; color: $color3; text-align: left; border-radius: 13px; }
      li { padding-bottom: 16px; font-weight: 400; font-size: 18px; }
    }
    .urgency:last-child { padding-bottom: 75px; }
    .urgency {
      & { margin: 0 auto; padding: 1rem 0; width: 95%; max-width: 775px; }
      h2 { font-family: "lato", sans-serif; font-weight: 700; font-size: 18px; text-align: left; margin: 15px auto 15px; }
      .urgency-slider { 
        & { position: relative; background-color: $color1opacity30; margin: 0 auto; padding: 115px 9% 80px; border-radius: 10px; }
        h3 { position: absolute; top: 0; color: #152036; font-weight: 600; font-size: 15px; left: 7%; }

        .slider-labels {
          & { width: 100%; font-weight: 400; font-size: 13px; margin: 0 auto; }
          .label {
            & { width: 20%; float: left; position: relative; }
            p { display: block; position: absolute; margin-left: -50%; margin-top: -20px; padding-top: 40px; width: 100%; text-align: center; z-index: 1000; }
          }
        }
      
      }

      .slider-points {
        & { margin-top: -20px; width: 100%; font-weight: 300; font-size: 14px; color: #343333; position: relative; }
        .score { position: absolute; z-index: 9999; margin-left: -13px; margin-top: -12px; text-align: center; font-size: 18px; font-weight: bold; color: #fff; }
        .top-labels { position: absolute; width: 1px; height: 33px; top: -46px; margin-left: -2px; background: #1F2A44; }
        .top-points { position: absolute; top: -70px; margin-left: -6px; font-weight: bold; font-size: 18px; color: #152036; }
        .point {
          & { cursor: pointer; width: 13px; height: 13px; border-radius: 8px; margin-left: -8px; margin-top: -6px; background: $color1; position: absolute; z-index: 9; }
        }
      }

      .custom-slider.MuiSlider-root { & { z-index: 1000; color: $color1opacity42; width: 100%; margin-left: 0px; }
        span.MuiSlider-track { left: -8px !important; }
     }

      .custom-slider .MuiSlider-rail { opacity: 0; }
      .custom-slider .MuiSlider-track { border: 8px solid $color1opacity60; height: 0; }

      .custom-slider .MuiSlider-thumb { background: $color1; }
      .custom-slider .MuiSlider-thumb:before { background: $color1; border: 4px solid $color1; width: 36px; height: 36px; box-shadow: 0px 0px 0px 0px rgba(247, 213, 77, 0.5); }
      .custom-slider.individual .MuiSlider-thumb:before { background: $color1; border: 4px solid $color1; width: 24px; height: 24px; box-shadow: 0px 0px 0px 0px rgba(247, 213, 77, 0.5); }
      .custom-slider .MuiSlider-thumb.Mui-focusVisible { box-shadow: 0px 0px 0px 8px rgba(247, 213, 77, 0.5); }
      .custom-slider .MuiSlider-thumb:hover { box-shadow: 0px 0px 0px 8px rgba(247, 213, 77, 0.5); }
      
      .MuiLinearProgress-root.custom-bar { background-color: #B8B8B8; height: 2px; }
      .custom-bar .MuiLinearProgress-bar { background-color: $color1; }
    }
  }

  // .testimonial-container {
  //   & { max-width: calc(880px - 4%); margin: 50px auto; padding: 75px 2% 30px; font-family: 'lato', sans-serif; font-size: 24px; line-height: 36px; color: #1F2A44; display: flex; }
  //   .avatar { margin-right: 53px; }
  //   .testimonial { 
  //     & { position: relative; color: #fff; font-family: 'lato', sans-serif; font-size: 21px; line-height: 1.35em; }
  //     .quotation-mark { position: absolute; margin-top: -50px; }
  //     .designation { 
  //       & { color: $color2; font-weight: 700; padding-top: 20px; }
  //       span {  font-weight: 400; }
  //     }
  //   }
  // }
  .testimonial-container {
    & { max-width: calc(880px - 4%); margin: 50px auto 0; padding: 75px 2% 30px; font-family: 'lato', sans-serif; font-size: 24px; line-height: 36px; color: $color3; display: flex; justify-content: center; }
    .testimonial { 
      & { position: relative; color: #ffffff; font-family: 'lato', sans-serif; font-size: 24px; line-height: 45px; text-align: center; }
      .testi { max-width: 55ch; }
      .avatar {
        img { border-radius: 50%; width: 120px; margin-top: 50px; margin-bottom: -20px; }
      }
      .quotation-mark { 
        & { display: block; text-align: left; font-size: 84px; color: $color1; margin-right: 15px; position: relative; line-height: 0; top: 10px; left: -20px; }
        // .quotation-block { position: absolute; right: 15px; top: -15px; }
      }
      .bottom { transform: scale(-1, -1); margin-left: 15px; text-align: left; top: -15px; left: 15px; }
      .designation { 
        & { color: $color1; font-weight: 700; padding-top: 32px; line-height: 1.1em; font-size: 32px; }
        span { font-weight: 600; font-size: 21px; color: #ffffff; }
      }
    }
  }

  .testimonial-invite {
    & { font-family: "Lato", sans-serif; background-color: $color3; margin: 2rem; margin-top: 0; padding: 3rem; color: #fff; border-radius: 10px; font-size: 1.5rem; }
    p { max-width: 46ch; margin: 1rem auto 2rem auto; }
    .cta-btn { margin: 3rem auto 2rem auto; }
  }

  .schedule-container {
    & { max-width: calc(880px - 10%); background: #fff; margin: 20px auto; padding: 45px 5% 45px; font-family: "lato", sans-serif; font-size: 21px; line-height: 32px; color: #122845; text-align: center; border-radius: 10px; }
    h3 { font-size: 36px; line-height: 10px; }
    .cta-btn {
      & { width: auto; display: inline-block; background: #2D8D95; margin: 40px auto 0; color: #fff; padding: 0; border-radius: 25px; }
      &:hover { background: $color2; color: $color3; transition: 0.3s; }
      a { display: block; text-decoration: none; color: #fff; }
      button { width: 220px; color: #fff; padding: 10px 0; font-family: "lato", sans-serif; font-size: 18px; border-radius: 3px; font-weight: bold; }
    }
  }
  .invite-legacy-container {
    & { display: flex; justify-content: center; align-items: center; max-width: 970px; margin: 0 auto; }
    .invite-legacy { & { background-color: #FFF; margin: 15px 25px; padding: 60px 2% 30px; font-family: "lato", sans-serif; font-size: 24px; font-weight: 700; line-height: 36px; color: $color3; text-align: center; border-radius: 10px; width: 100%; }
      p { margin: 0 auto; max-width: 45ch; padding: 0 30px; }
      .cta-btn {
        & { width: 100%; max-width: 280px; background: $color1; margin: 45px auto 30px; color: #fff; border-radius: 3px; }
        &:hover { background: $color2; color: $color3; transition: 0.3s; color: #fff; }
        a { display: block; text-decoration: none; color: #fff; }
        button { width: 100%; color: #fff; padding: 7px 5px; font-family: "lato", sans-serif; font-size: 21px; border-radius: 3px; font-weight: 700; }
      }
    }
  }

}

.btn { & { }}

footer { min-height: 100px; }

@media only screen and (min-width: 650px) {

  main .assessment-additional .steps .slider-container .slider-labels br { content: ""; }
  main .assessment-additional .steps .slider-container .slider-labels br:after { content: " "; }

}

@media only screen and (max-width: 1250px) {

  header {
    .logo { margin-left: 0; }
    .bleat-logo { right: 50px; }
  }
  
  .custom-slider.MuiSlider-root { padding: 12px 0; }  

}

@media only screen and (max-width: 1110px) {

  main .dashboard .urgency { overflow: hidden; }
  main .testimonial-container .testimonial .testi br { content: ""; }
  main .testimonial-container .testimonial .testi br:after { content: " "; }

}

@media only screen and (min-width: 831px) {
  // main .bleat-logo-mobile { display: none; }
  main .container.start { margin: 80px auto; width: 96%; }
}

@media only screen and (max-width: 830px) {

  header {
    & { height: auto; // flex-direction: column; align-items: flex-start; 
    }
    .header-wrapper { grid-template-columns: 0.25fr 1fr 0.25fr; }
    .logo { position: relative; margin: 0 auto; left: 0; margin: 20px auto; padding: 0 0 0 10px; width: auto; }
    .assessment-title { margin: 10px auto; 
      .logo-text { font-size: 28px; line-height: 42px; }
      .logo-subtext { font-size: 20px; line-height: 25px; letter-spacing: 4px; }
    }
    .bleat-logo { display: none; }
  }

  footer { & { min-height: 0; height: 0; }
    .bleat-logo-mobile { 
      & { display: flex; justify-content: center; padding: 2rem 0; text-align: center; width: 100%; }
      img { height: 43px; width: 105px; }
    }
  }
  
  main {
    .container { margin-bottom: 60px; }
    .testimonial-container .testimonial { font-size: 21px; }
    .invite-legacy-container .invite-legacy { margin-bottom: 20px; font-size: 21px; }
    .container.start { margin: 15px auto; }
    .start {
      & { flex-direction: column; height: auto; width: 95%; }
      .video { width: 100%; height: auto; height: 75vw; border-radius: 10px 10px 0 0; }
      .intro { 
        & {width: 90%; padding: 30px 5%; height: auto; }
        h1 { text-align: center; width: auto; font-size: 2rem; margin: 0 auto 15px; max-width: 25ch; }
        h2 { text-align: center; width: 100%; max-width: 34ch; margin: auto; }
        .intro-points-list { width: auto; max-width: 34ch; margin: 15px auto; }
        .completion { 
          & { flex-direction: column; margin: 30px auto 0 auto; }
          .time-estimate { margin: 15px auto; }
          .cta { margin-bottom: 0; }          
        }
      }
    }
    .results .result-text { width: 95%; box-sizing: border-box; font-size: 20px; }
  }
  
  main .start .video video { width: 120%; margin: 0 -10%; object-fit: cover; }
  main .start .video .thumbnail { width: 100%; height: auto; }
  main .assessment .steps {
    .progress-percent-text { display: none; }
    .question { font-size: 21px; }
    .progress-bar { 
      // & { display: grid; grid-template-columns: 1fr 1fr; grid-gap: 1rem; }
      & { display: flex; justify-content: center; }
      .bar-section { width: 90% !important; }
    }
    .current-section { margin: 10px auto 0; }
    .progress-indicator { margin: 30px auto; }
    .slider-container .slider-labels { & { font-size: 16px; }
      .label { max-width: 10ch; }
    }
  }
  main .details .form, main .pre-additional-questions-details .form { width: 85%; }
  main .assessment .steps .progress-bar .bar-section .section-title { font-size: 16px; }
  main .results .selection-form { & { font-size: 28px; }
    .form-details-text .MuiInput-root { font-size: 28px; }
  }
  main .dashboard table { font-size: 15px; }
  main .dashboard .most-answered { width: 90%; }
  main .dashboard .urgency { margin: 1rem auto; max-width: 90%; }
  main .dashboard .hurdles-enablers { width: 90%; }  

}

@media only screen and (max-width: 900px) and (min-width: 831px) {
  main .start .intro {
    h1 { font-size: 36px; line-height: 48px; }
    .intro-points-list { font-size: 21px; }
  }
}

@media only screen and (min-width: 650px) {

  // main .dashboard .urgency .urgency-slider .slider-labels .label p br { content: ""; }
  // main .dashboard .urgency .urgency-slider .slider-labels .label p br:after { content: " "; }

}

@media only screen and (max-width: 650px) {

  header { .header-wrapper { display: grid; grid-template-columns: 80px 1fr 80px; height: auto; }
    .logo img { height: 47px; width: auto; max-width: 60px; }
    .assessment-title { margin: 10px auto; img { height: auto; }
      .logo-text { font-size: 24px; line-height: 28px }
      .logo-subtext { font-size: 18px; line-height: 25px; }}
  }
  main .assessment .steps { width: 90%; padding: 0 5%; }
  main .start .video {
    .playbtn { width: 144px; height: 144px; }
    .caption { & { padding: 4px 0 4px; }
      p { margin: 1px auto; font-size: 14px; line-height: 17px; }
      p.name { font-weight: 700; font-size: 18px; line-height: 22px; }
    }
  }
  main .start .intro h1 { text-align: center; }
  main .start .intro h2 { text-align: center; font-size: 1.25rem; line-height: 25px; }
  main .start .intro h1 br { content: ""; }
  main .start .intro h1 br:after { content: " "; }
  main .assessment .mobile-spacer { display: none; }
  main .assessment .steps .progress-bar .bar-section .progress-percentage { font-size: 1rem; margin: 1rem 0 0.5rem 0.75rem; }
  main .pre-additional-info { & { padding: 15px; } h1 { font-size: 21px; } p { font-size: 18px; } }
  main .details, main .pre-additional-questions-details {
    .form { & { width: 90%; } 
      .form-detail { flex: 0 1 100%; max-width: 100%; }
      .input-title { max-width: 100%; }
      // .error-text { margin: 0 auto -10px; }
    }
  }
  main .results {
    h1 { font-size: 30px; padding: 24px 16px 16px; }
    .progress-bar { width: 90%; position: relative; margin: 75px auto 100px; }
    .result-sections .score-section { font-weight: 400; }
    // .pervalue h4.nimp1:after { content: '0%'; position: absolute; right: -19px; bottom: -15px; }
    // .pervalue h4.nimp:after { content: '45%'; position: absolute; right: -19px; bottom: -15px; }
    // .pervalue h4.mexpe:after { content: '70%'; position: absolute; right: -19px; bottom: -15px; }
    // .pervalue h4.eexpe:after { content: '100%'; position: absolute; right: -19px; bottom: -15px; }

    .pervalue-text { & { margin: 10px auto; position: relative; width: 100%; }
      p { display: inline-block; text-align: center; margin: 0 auto; font-size: 12px; color: #4A4A4A; }
    }
    .score-section-container {
      & { flex-wrap: wrap; padding: 0 0 20px 0; max-width: 90%; margin-top: 0; }
      .score-section { 
        // & { margin: 10px auto; min-width: 200px; width: 75%; max-width: 350px; }
        & { margin: 10px auto; flex: 0 1 50%; max-width: 50%; }
        .score-txt { font-size: 18px; font-weight: 700; margin: 0.25rem auto; }
        .score-name { font-size: 18px; font-weight: 400; margin: 0.25rem auto; }
        .score-slider { width: 90%; margin: 0 auto; margin-bottom: 16px; }
        
      }
    }
  }
  main .invite-container { font-size: 1.1rem; color: white; margin: 0 auto;
    .invite-container-spacer { padding: 1rem; 
      h3 { font-size: 24px; line-height: 43px; margin: 1.5rem auto; padding: 0 15px; }
      .invite-reasons {
        flex-wrap: wrap;
        padding: 20px 0 20px;
        
        .reason { flex: 0 1 100%; font-size: 18px; max-width: 27ch; margin: 2rem auto; }
        .reason span { color: $color1; }
        .reason img { } 
      }
    }
  }
  main .testimonial-container .testimonial { & { color: #fff; margin: 20px auto 0; text-align: center; }
    .quotation-mark { color: $color1; font-size: 46px; }
    .testi { font-size: 18px; line-height: 45px; }
    .designation { color: $color1; 
      span { color: #fff; font-size: 18px; }
    }
  }
  main .details h1, main .pre-additional-questions-details h1 { font-size: 28px; }
  main .testimonial-invite {
    & { background: #DCDBDB; margin: 0 auto; padding: 3rem 2rem; color: #27282A; border-radius: 10px; font-size: 21px; font-weight: 700; }
    p { max-width: 40ch; margin: 1rem auto; line-height: 43px; }
    .cta-btn { margin: 2rem auto 0rem auto; }
  }
  main .dashboard {
    h1 { font-size: 30px; padding: 24px 16px 16px; }
    .progress-bar { & { width: 90%; position: relative; margin: 90px auto 100px; } 
      .pervalue-text { & { margin: 10px auto; bottom: -65px; }
        p { font-size: 12px; } }
    }
    .alignment .tab-buttons button { font-size: 16px; padding: 10px 12px; }
    .result-text p { margin: 15px auto; }
  }
  main .invite-legacy-container .invite-legacy { & { padding: 3rem 1rem; }
    .cta-btn { margin: 2rem auto 0 auto; }
  }

}


@media only screen and (max-width: 550px) {
  
  header .assessment-title .logo-text { font-size: 20px; line-height: 24px; }
  main .start { margin-top: 15px; }
  main .start .video { height: 80vw; border-top-left-radius: 10px; border-top-right-radius: 10px; border-bottom-left-radius: 0; border-bottom-right-radius: 0; }
  main .start .intro h1 { font-size: 21px; line-height: 25px; max-width: 24ch; margin: 0 auto 15px; }
  main .start .intro h2 { font-size: 17px; max-width: 34ch; }
  main .start .intro .intro-points-list { font-size: 17px; max-width: 34ch; }
  main .start .intro .completion img { margin: 0 4px 0 0;}
  
  main .assessment { margin-top: 15px; }
  main .assessment .steps {
    .question { min-height: 180px; font-size: 21px; max-width: 100%; display: flex; justify-content: center; align-items: center; }
    .progress-bar .bar-section .section-title { font-size: 12px; }
    .slider-container .slider-labels .label { width: 14%; }
    .progress-indicator { margin: 45px auto 5px; font-size: 18px; }
    .progress-bar { grid-gap: 0; }
    .progress-bar .bar-section .bar { height: 6px; margin: 0 5px 0; }
    .progress-bar .bar-section .section-title { font-size: 12px; }
    .slider-container .slider-labels { font-size: 14px; }
    .slider-container .slider-labels .label p.desktop { display: none; }
    .slider-container .slider-labels .label p.mobile { display: block; }
    .radio-container .slider-labels { font-size: 12px; }
    .radio-container .slider-labels .label p.desktop { display: none; }
    .radio-container .slider-labels .label p.mobile { display: block; }
    .step-buttons { margin: 30px auto 30px; padding-bottom: 20px; }
  }
  
  main .assessment-additional { & { margin-top: 15px; }
    .steps .textarea-field { width: 100%; margin-left: -10px; }
    .steps .form-details-text { height: 160px; }
    .steps .question { font-size: 18px; }
    .steps .question p br { content: ""; }
    .steps .question p br:after { content: " "; }
    .steps .slider-container .slider-labels { font-size: 12px; }
    .steps .step-buttons { padding-bottom: 30px; }
  }
  // main .assessment .steps .slider-container .custom-slider.MuiSlider-root { width: calc(100% + 25px); margin-left: -6px; }
  main .assessment .steps .slider-container .slider-labels .label p { width: 145%; margin-left: -75%; letter-spacing: 0px; }

  main .details { & { width: 95%; margin-top: 15px; }
    h1, main .pre-additional-questions-details h1 { font-size: 24px; }
    p { padding: 0 5%; font-size: 18px; }
    p br { content: ""; }
    p br:after { content: " "; }
    .form, main .pre-additional-questions-details .form { width: 90%; }
    .form .form-detail .form-details-text { padding-right: 0; max-width: 100%; }
    // .details-buttons { & { max-width: 90%; display: flex; flex-direction: column-reverse; }
    .details-buttons { & { max-width: 90%; display: flex; }
      .back-btn { align-self: flex-start; }
      .cta-btn { & { width: auto; }
        button { width: auto; padding: 8px 1.5rem; } }
    }
  }
  // main .details .form .form-detail .form-details-text.react-select { max-width: 97.5%; }

  main .results { width: 95%; margin-top: 15px; }
  main .results .selection-form { width: max-content; max-width: 95%; flex-wrap: wrap; }
  main .results .selection-form .form-detail { width: 100%; margin-right: 0; }
  main .results .selection-form .form-text { width: 100%; margin-bottom: 10px; text-align: center; font-size: 18px; margin-top: 8px; }
  main .results h1 { font-size: 24px; padding: 1.5rem 1rem 1rem; width: auto; }
  main .results .result-text { & { margin: 20px auto; font-size: 18px; }
    p { margin: 15px auto; max-width: 29ch; }
  }
  main .results .result-sections { margin: 50px 5%; padding-bottom: 50px; }
  main .results .result-sections .score-section { flex: 0 1 100%; margin-bottom: 20px; }
  main .invite-container .cta-btn { & { width: 100%; max-width: 90%; } 
    button { font-size: 18px; padding: 8px 16px; line-height: 16px; width: 100%; }}
  main .results .result-sections .score-section .score-txt { margin-bottom: 9px; }
  main .results .progress-bar { & { width: 90%; }
    .pervalue h4::after { font-size: 13px; bottom: 40px; }
    .pervalue h4.eexpe:after { right: -10px; }
  }
  main .results .si_pro_header h4 { font-size: 13px; }
  // main .results .si_pro_header h4:before { bottom: -83px !important; }
  // main .results .si_pro_header h4.nimp:before { bottom: 63px; }
  // main .results .si_pro_header h4.mexpe:before { bottom: 63px; }
  main .results .si_ass_progress .msi_score { font-size: 26px; }
  main .results .members-answered { width: 95%; font-size: 16px; }
  main .results .members-answered .answered-detail { font-size: 14px; padding: 10px 15px; width: calc(100% - 30px); }
  main .results .score-section-container { & { flex-direction: row; }
    .score-section { width: 100%; max-width: 75%; }
  }

  main .invite-container { max-width: 95%; font-size: 18px; padding: 0 15px 30px; }
  main .invite-container p br { content: ""; }
  main .invite-container p br:after { content: " "; }
  main .invite-container .invite-container-spacer h3 { font-size: 21px; line-height: 28px; }
  main .invite-container .invite-reasons { flex-wrap: wrap; padding: 20px 0 20px; }
  main .invite-container .invite-reasons .reason { flex: 0 1 100%; font-size: 18px; margin-bottom: 50px; margin-top: 20px; }

  main .team-container { width: 90%; font-size: 18px; }
  main .team-container h3 { font-size: 24px; line-height: 38px; }

  main .schedule-container { width: 85%; font-size: 18px; }
  main .schedule-container h3 { font-size: 24px; }
  // main .testimonial-container { flex-wrap: wrap; padding-top: 12px; }
  // main .testimonial-container .avatar { flex: 0 1 100%; text-align: center; margin-right: 0; }

  main .testimonial-container { flex-wrap: wrap; padding-top: 12px; }
  main .testimonial-container .avatar { flex: 0 1 100%; text-align: center; margin-right: 0; }
  main .testimonial-container .testimonial .quotation-mark { font-size: 46px; }
  main .testimonial-container .testimonial .designation { font-size: 26px; }
  main .testimonial-container .testimonial .designation span { padding-top: 6px; }

  main .assessment-additional .steps .textarea { min-height: 120px; }
  main .assessment-additional .steps .textarea-field { margin-bottom: -77px; }
  main .invite { width: 95%; margin-top: 15px; }
  main .invite h1 { font-size: 24px; width: auto; }
  main .invite h1 br { content: ""; }
  main .invite h1 br:after { content: " "; }
  main .invite p { margin: 5px 2% 10px; font-size: 18px; }
  main .invite p br { content: ""; }
  main .invite p br:after { content: " "; }
  main .invite .form { width: 75%; }
  main .invite .form .form-detail { flex: 0 1 100%; }
  main .invite .form .form-detail .invite-field { max-width: 100%; }
  main .invite .form .form-detail .error-text { bottom: auto; }
  main .invite .form .invite-number { left: -25px; top: 5px; }
  main .invite .form .delete-btn { right: auto; left: -32px; top: 45px; }
  main .invite-legacy-container .invite-legacy { & { font-size: 21px; margin: 15px 12px; }
    p { padding: 0 15px; }
  }

  main .invite .invite-another-person .invite-another { & { top: 25px; right: -26px; left: 0; margin-left: -18%; }
    .add-btn { font-size: 18px; }
  }
  main .invite .cta-btn { margin-top: 20px; margin-right: 12%; width: 164px; min-width: 160px; padding: 4px 0; width: auto; padding: 4px 15px; font-size: 20px; font-size: 18px; }
  main .thank-you { width: 95%; }
  main .thank-you h1 { font-size: 24px; max-width: 24ch; }
  main .thank-you p { width: 94%; margin: 25px 3% 35px; font-size: 18px; }
  main .testimonial-container .testimonial { font-size: 18px; width: 90%; margin: 20px auto 0; text-align: center; }

  
  main .MuiSwitch-thumb { background-color: $color1 !important; position: relative; }
  main .MuiSwitch-thumb::after { position: absolute; top: 50%; left: 50%; transform: translate(-50%, -50%); content: ""; width: 3px; height: 3px; border: 3px solid #FFFFFF; border-radius: 50%; padding: 2px; background-color: transparent !important; }
  main .MuiSwitch-track { background-color: #E7E9EB !important; opacity: 1 !important; }
  main .individual-group { display: none; }
  main .toggle-individual-group { display: block; margin: 15px auto; display: flex; align-items: center; justify-content: center; color: #fff; font-size: 21px; }
  main .toggle-individual-group .left { flex: 0 1 25%; text-align: right; color: #ffffff; }
  main .toggle-individual-group .right { flex: 0 1 35%; color: #ffffff; }
  main .toggle-individual-group .active { font-weight: 700; color: $color1; }
  main .dashboard { width: 95%; margin-top: 15px; }
  main .dashboard h1 { font-size: 24px; padding: 1.5rem 1rem 1rem; width: auto; }
  main .dashboard .mobile_scores { display: block; }
  main .dashboard .result-text { margin: 20px 5%; font-size: 18px; }
  main .dashboard .result-sections { margin: 50px auto; padding-bottom: 0; }
  main .dashboard .result-sections .score-section { flex: 0 1 50%; }
  main .dashboard .result-sections .score-section .score-txt { margin: 10px auto; }
  main .dashboard .result-sections .score-section .score-name { font-weight: 400; margin: 10px auto 0; }
  main .dashboard .progress-bar { width: 90%; }
  main .dashboard .si_pro_header h4 { font-size: 13px; }
  // main .dashboard .si_pro_header h4.nimp:before { bottom: 63px; }
  // main .dashboard .si_pro_header h4.mexpe:before { bottom: 63px; }
  main .dashboard .progress-bar .pervalue { h4::after { font-size: 13px; bottom: -12px; }
    h4.eexpe:after { right: -10px; }
  }
  main .dashboard .si_ass_progress .msi_score { font-size: 26px; }
  main .dashboard hr { display: none; }
  main .dashboard table { font-size: 13px; display: none; }
  main .dashboard table .footer .church-average { font-size: 13px; }
  main .dashboard table .header .center-cell { font-size: 0; }
  main .dashboard .urgency h2 { text-align: center; }
  main .dashboard .urgency .urgency-slider h3 { text-align: center; left: 0; width: 100%; }
  main .dashboard .urgency .slider-points .top-points { font-size: 13px; }
  main .dashboard .urgency .urgency-slider .slider-labels { font-size: 12px; }
  main .dashboard .most-answered { font-size: 16px; }
  main .dashboard .most-answered .most-answered-detail { font-size: 14px; }
  main .dashboard .alignment .content .align-details .answered-detail { font-size: 14px; }
  main .dashboard .alignment { & { width: 90%; }
    .tab-buttons { & { flex-wrap: wrap; padding: 10px; box-sizing: border-box; }
      button { & { font-size: 15px; min-width: 55px; padding: 10px 5px; flex: 0 1 50%; border-radius: 3px; color: #FFFFFF; }
        &.active { color: #152036; }
      }
    }
    .tabs-content { background-color: #246080; padding: 0 10px 10px; }
  }
  main .dashboard .hurdles-enablers ul { & { padding-left: 30px; } 
    li { font-size: 14px; } }
  main .dashboard .alignment .content .align-details { font-size: 16px; }
  main .dashboard .alignment .content .align-details .answered-detail p { flex: 0 1 90%; }
  main .dashboard .alignment .content .align-details .answered-detail .more-toggle { flex: 0 1 8%; }
  main .results .selection-form .form-details-text .MuiInput-root { font-size: 24px; }
  main .dashboard .alignment .content .align-details.analyze .answered-detail { flex-direction: row-reverse; }
  main .results, main .dashboard {
    .hurdles-enablers {
      h2 { font-size: 16px; }
      li { font-size: 16px; }
    }
    .urgency {
      h2 { font-size: 16px; }
      .custom-bar .MuiLinearProgress-bar { background-color: #009DF133; }
      .urgency-slider { margin: 0; background: transparent; }
      .urgency-slider.individual { padding-top: 45px !important; }
      .custom-slider.MuiSlider-root { color: transparent; }
      .custom-slider .MuiSlider-track { border: 0px; }
      .MuiLinearProgress-root.custom-bar { background-color: #009DF133; height: 8px; margin-top: -22px; border-radius: 5px; width: calc(100% + 3px); margin-left: -2px; }
      .slider-points .point { width: 8px; height: 8px; margin-top: -2px; margin-left: -5px; }
    }
  }
}

@media only screen and (max-width: 550px) {

  main .assessment .steps .progress-bar .bar-section .section-title { font-size: 14px; margin-bottom: 31px; }  
  main .start .intro .completion { & { margin: 30px auto 0; width: 100%; justify-content: center;  }
    .time-estimate { margin-bottom: 0; }
  }
  main .start .intro .completion .cta { margin: auto 15% 0; }

}

@media only screen and (max-width: 450px) {

  // main .dashboard table { font-size: 11px; }
  // main .dashboard table .footer .church-average { font-size: 11px; }

}

// Tabs CSS


// .tabs {
//   padding: 15px 0px;
//   text-align: center;
//   color: white;
//   width: 100%;
  
//   cursor: pointer;

//   box-sizing: content-box;
//   position: relative;
//   outline: none;
// }
// .active-tabs {
//   color: #000;
//   background: $color1;
//   border-bottom: 1px solid transparent;
//   &::before {
//     content: "";
//     display: block;
//     position: absolute;
//     top: -5px;
//     left: 50%;
//     transform: translateX(-50%);
//     width: calc(100%);
//     height: 0px;
//     background: rgb(88, 147, 241);
//   }
// }
// button {
//   border: none;
// }

